/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
@import url("https://fonts.googleapis.com/css?family=Quicksand:300,500");

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
html,
body,
#root {
  height: 100%;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: "Quicksand", "Roboto", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
}

:root {
  --main-bg-color: #f5f5f5;
  --main-txt-color: #fff;
}

body {
  background-color: var(--main-bg-color);
  color: var(--main-txt-color);
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}

.container {
  width: 100%;
  /* max-width: 750px; */
  margin: 0 auto;
}
