.footer {
  padding: 64px;
  background: #363636;
  color: white;
  text-align: center;
  margin-top: auto;
}

.footer span {
  color: red;
}
