.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cards__item {
  display: flex;
  padding: 1rem;
  justify-content: center;
}

@media (min-width: 56rem) .cards__item {
  width: 33.3333%;
}

@media (min-width: 40rem) .cards__item {
  width: 50%;
}

.card {
  background-color: #ffffff;
  border-radius: 0.25rem;
  box-shadow: 0 1px 4px rgb(0 0 0 / 16%);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 25rem;
  max-width: 80%;
}

.card__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
}

.card__image img {
  width: 100%;
}
.card__title {
  font-size: 1rem;
  font-weight: 800;
  color: #000;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.card__text {
  flex: 1 1 auto;
  font-size: 0.875rem;
  color: #848489;
  line-height: 1.5;
  margin-bottom: 1.25rem;
  margin-block-start: 1rem;
}
