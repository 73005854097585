.course_title {
  padding-block-start: 1rem;
  padding-inline-start: 0.5rem;
  color: black;
}

.course_container {
  display: flex;
}

.curriculum_list {
  list-style: none;
  padding: 0px;
  width: 30%;
  height: 750px;
  overflow-y: scroll;
  background: #e7e7e7;
  border-radius: 5px;
}

.chapter_title {
  color: black;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: white;
  border-radius: 0.5rem 0.5rem 0 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
}

@media screen and (max-width: 600px) {
  .course_container {
    flex-direction: column-reverse;
  }

  .curriculum_list {
    width: inherit;
  }
}
