.background {
  padding: 1rem 0;
  /* border-bottom: 1px black solid; */
  box-shadow: 0px -2px 6px #000000;
}

.title {
  font-family: "Press Start 2P", cursive;
  text-align: center;
  font-size: 1.5rem;
}

.header_content {
  display: flex;
  flex-wrap: wrap;
}

.left,
.right {
  flex-basis: 50%;
  display: flex;
}

.left {
  padding-left: 1rem;
}

.right {
  padding-right: 1rem;
}

.right > button {
  margin-left: auto;
}
