.player_wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  margin: 0.5rem;
}

.react_player {
  position: absolute;
  top: 0;
  left: 0;
}

.player_error_wrapper {
  background: black;
  display: flex;
  flex-direction: column;
  padding-top: 25%;
  padding-bottom: 25%;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  max-height: "700px";
}
